<template>
  <div>
    <table-list
      class="tableList"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :title="tableTitle"
      :options="{ selection: true }"
      :columns="columns(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格标题
const tableTitle = '学习统计'
const btns = _this => [
  {
    label: '导出记录',
    type: 'primary',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '姓名',
    prop: 'userId',
    defaultValue: _this.userId,
    type: 'select',
    children: _this.selectTypeList.map(item => ({
      value: item.userId,
      label: item.userName,
    })),
  },
  {
    label: '课程',
    prop: 'courseId',
    type: 'select',
    children: _this.classNameList.map(item => ({
      value: item.id,
      label: item.courseName,
    })),
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '姓名',
    minWidth: '70px',
  },
  {
    prop: 'phone',
    label: '手机号',
    minWidth: '120px',
  },
  {
    prop: 'courseName',
    label: '课程名称',
    minWidth: '150px',
    twoLines: true,
  },
  {
    prop: 'studyDuration',
    label: '已学时长数',
    align: 'right',
    minWidth: '100px',
  },
  {
    prop: 'courseDuration',
    label: '应学时长数',
    align: 'right',
    minWidth: '100px',
  },
  {
    prop: 'studySectionCount',
    label: '已学章节数',
    align: 'right',
    minWidth: '100px',
  },
  {
    prop: 'sectionCount',
    label: '应学章节数',
    align: 'right',
    minWidth: '100px',
  },
  {
    prop: 'progress',
    label: '学习进度',
    minWidth: '120px',
    align: 'center',
    sortable: 'sortable',
  },
]

import TableList from '@/components/TableList'
import {
  getClassStudy,
  classCertCourse,
  getClassStudyInfo,
  downloadClassStudyInfoExport,
} from '@/api/certCenter'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      btns,
      searchForm,
      columns,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      select: [],
      list: [],
      selectTypeList: [],
      classNameList: [],

      userId: 0,
      certRelationTrainId: 0,
      privateTrainId: 0,
    }
  },
  created() {
    this.userId = parseInt(this.$route.query.userId)
    this.certRelationTrainId = parseInt(this.$route.query.certRelationTrainId)
    this.privateTrainId = parseInt(this.$route.query.privateTrainId)

    this.queryParams = { userIds: [this.userId] }

    this.getCertCourseList()
    this.selectTypeData()
    this.onGetList()
  },
  methods: {
    //数组[字典]去重
    dataHeavy(data, key) {
      let obj = {}
      let peon = data.reduce((cur, next) => {
        obj[next[key]] ? '' : (obj[next[key]] = true && cur.push(next))
        return cur
      }, [])
      return peon
    },
    async onGetList() {
      console.log(this.queryParams)
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(
        getClassStudyInfo({
          current,
          size,
          ...this.queryParams,
          privateTrainId: this.privateTrainId,
          certRelationTrainId: this.certRelationTrainId,
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data ? res.data.list : []
      console.log(res)
      this.pager.total = res.data.total
    },
    //人
    async selectTypeData() {
      const { current } = this.pager
      const [res, err] = await to(
        getClassStudy({ current, size: 100000, certRelationTrainId: this.certRelationTrainId }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.selectTypeList = this.dataHeavy(res.data.list, 'userId')
      console.log(this.selectTypeList)
    },
    //课程
    async getCertCourseList() {
      if (this.loading) return
      const { current } = this.pager
      this.loading = true
      const [res, err] = await to(
        classCertCourse({ current, size: 100000, certPrivateTrainId: this.privateTrainId }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.classNameList = res.data.list
    },

    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      if (queryParams.courseId) {
        let resourceMaps = []
        for (var i = 0; i < this.classNameList.length; i++) {
          let info = this.classNameList[i]
          if (info.id == queryParams.courseId) {
            resourceMaps.push({
              relationId: info.relationId,
              relationType: info.relationType,
            })
            this.queryParams.resourceMaps = resourceMaps
            break
          }
        }
      } else {
        this.queryParams.resourceMaps = []
      }
      if (queryParams.userId) {
        this.queryParams.userIds = [queryParams.userId]
      } else {
        this.queryParams.userIds = []
      }

      this.onGetList()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    async handleExport() {
      let resourceList = []
      this.select.map(v =>
        resourceList.push({
          relationType: v.relationType,
          relationId: v.relationId,
        }),
      )
      this.queryParams.resourceMaps = resourceList

	let certIdList = []
	if (this.select.length > 0) {
		console.log(1231);
		this.select.map(v => certIdList.push(v.userId))
		this.queryParams.userIds = certIdList
	}
      const { current, size } = this.pager
      const [res, err] = await to(
        downloadClassStudyInfoExport({
		  privateTrainId:this.privateTrainId,
		  certRelationTrainId:this.certRelationTrainId,
          current,
          size,
          ...this.queryParams,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '学习记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.onGetList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.onGetList()
    },
  },
}
</script>

<style scoped lang="scss"></style>
